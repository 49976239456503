import { ComplainUpdate } from '../../../swagger/models'
import { useEffect, useState } from 'react'
import { useCombinedStore } from "../../../store"
import { notifyError } from '../../../helpers/helper'
import { dateTimeHelper } from '../../common-module/helper'
import { useTranslation } from 'react-i18next';
import OutcomeDetails from './OutcomeDetails'
import { Tooltip } from 'react-tippy'
import DocumentViewerModal from '../../DocumentViewerModal'

interface ComplaintUpdatesProps {
  complaintDetail?: any,
  user?: any
}


function ComplaintUpdates({ complaintDetail, user }: ComplaintUpdatesProps) {
  const { t } = useTranslation();
  const [newUpdate, setnewUpdate] = useState<ComplainUpdate>({})
  const [complainUpdateId, setComplainUpdateId] = useState<number>()
  const [selectedFile, setSelectedFile] = useState<any>({})
  const [previewLink, setPreviewLink] = useState<string>('')

  const fetchCaseUpdates = useCombinedStore((state: any) => state.fetchCaseUpdates);
  const createUpdate = useCombinedStore((state: any) => state.createUpdate);
  const redirectUserToODR = useCombinedStore((state: any) => state.redirectUserToODR);
  const redirectionURL = useCombinedStore((state: any) => state.redirectionURL);

  const updates: ComplainUpdate[] = useCombinedStore((state: any) => state.updates)
  const updateFiles = useCombinedStore((state: any) => state.updateFiles)

  const uploadFile = useCombinedStore((state: any) => state.uploadCommentFile)
  const downloadFileOfComplaint = useCombinedStore((state: any) => state.downloadFile)
  const deleteFile = useCombinedStore((state: any) => state.deleteUpdateFiles)
  const allowedStatuses = ['CONCILIATION_FAILED', 'DISPUTE_RESOLVED_-_CONCILIATION', 'DISPUTE_RESOLVED_-_ARBITRATION']

  useEffect(() => {
    fetchCaseUpdates(complaintDetail.complainId)
  }, [])

  useEffect(() => {
    if (redirectionURL) {
      window.open(redirectionURL, '_blank', 'noreferrer');
    }
  }, [redirectionURL])

  const addUpdate = (e: any) => {
    if (user) {
      if (!newUpdate.comment) {
        notifyError(t('DescriptionMissing'));
        return
      }
      let updateObject = {
        files: updateFiles.length > 0 ? updateFiles : [],
        comment: newUpdate.comment,
        complainId: complaintDetail?.complainId,
        userId: user?.userId,
        userType: user?.userType,
        disputetype: complaintDetail.disputeType ? complaintDetail.disputeType : "conciliation"
      }
      setnewUpdate({
        comment: ''
      })
      createUpdate(updateObject)
      fetchCaseUpdates(complaintDetail.complainId)
    } else {
      notifyError(t('UnauthorizedUpdate'))
    }
  }

  const handleUpdateFiles = (e: any) => {
    const file = e.target.files;
    const formData = new FormData();
    formData.append('file', file);
    let fileSize = 0
    for (const f of file) {
      fileSize += f.size;
    }
    if (fileSize > 20 * 1000 * 1024) {
      notifyError(t('UploadFileExceeds'))
      return false;
    }
    for (const f of file) {
      uploadFile(f, "", "", "");
    }
  }

  const downloadFile = async (key: string, complainUpdateId: number) => {
    let res = await downloadFileOfComplaint(key, complaintDetail?.complainId, null, complainUpdateId, null)
    if (!res.error) window.open(res.file)
  }

  useEffect(() => {
  }, [newUpdate])

  const removeFile = (fileName: any) => {
    deleteFile(fileName)
  }

  const redirectToSama = () => {
    redirectUserToODR(complaintDetail?.complaintUUID)
  }

  const generateLink = async (key: string, complainUpdateId: number, file: any) => {
    try {
      let res = await downloadFileOfComplaint(key, complaintDetail?.complainId, undefined, complainUpdateId, undefined)
      setPreviewLink(res.file)
      setSelectedFile(file)
      setComplainUpdateId(complainUpdateId)
    } catch (error: any) {
      notifyError(error?.response?.data?.message);
    }
  }

  useEffect(() => {
    document.body.style.overflow = previewLink && previewLink !== "" ? "hidden" : "unset";
  }, [previewLink]);

  return (
    <div className="flex flex-col w-full md:flex-row justify-between p-6 bg-gray-100">
      <div className="w-full md:w-2/3 pr-4">
        <div className="flex flex-col  h-full gap-4 pt-6">
          {complaintDetail?.odrId &&
            <div className='w-full'>
              <div className='w-full  flex flex-col mb-6 gap-3 text-white bg-highlight-blue rounded-xl sm:px-10 lg:px-20 px-20 py-6 text-center'>
                <p className='sm:text-sm text-[10px]'>{t('DisputeWithOrganisationName',
                  { organisationName: complaintDetail?.ODR?.organisationName }
                )} </p>
                <button onClick={redirectToSama} className='font-bold sm:text-2xl text-base underline'>{t('ClickToViewCase')}</button>
              </div>
            </div>
          }
          <div className="flex flex-col">
            {updates?.map((step, index) => (
              <div key={index} className="flex items-center mb-7 h-fit">
                <div className="relative h-full">
                  <div className={index !== updates.length - 1 ? "flex items-center justify-center w-9 h-9 rounded-full border-2 border-border-grey bg-white" : "flex items-center justify-center w-9 h-9 rounded-full border-2 border-highlight-blue bg-white"}>
                    <i className={index !== updates.length - 1 ? `ph-bold ph-${step?.otherMetaData?.icon} align-middle text-low-grey` : `ph-bold ph-${step?.otherMetaData?.icon} align-middle text-highlight-blue`}></i>
                  </div>
                  {index !== updates.length - 1 && (
                    <div className="absolute top-9 left-4 h-full w-0.5 bg-border-grey"></div>
                  )}
                </div>
                <div className="ml-4 flex flex-col md:w-2/3 xl:w-1/3 gap-1">
                  {step.userType === "system" ? (

                    <p className={index !== updates.length - 1 ? "text-updates-grey font-semibold" : "text-highlight-blue font-semibold"}>{step.comment}</p>
                  ) : (

                    step.userType === "ODR" ? (
                      <p className={index !== updates.length - 1 ? "text-updates-grey font-semibold" : "text-highlight-blue font-semibold"}>{t('ODRInstitutionUpdate', {
                        organisationName: step?.User?.Organisation?.organisationName
                      })}</p>
                    ) : (
                      step.userType === "MII" ? (
                        <p className={index !== updates.length - 1 ? "text-updates-grey font-semibold" : "text-highlight-blue font-semibold"}>{t('MIIUpdate', {
                          organisationName: step?.User?.Organisation?.organisationName
                        })}</p>
                      ) : step.userType === "Intermediary" ? (

                        <p className={index !== updates.length - 1 ? "text-updates-grey font-semibold" : "text-highlight-blue font-semibold"}>{t('IntermediaryUpdate', {
                          organisationName: step?.User?.Organisation?.organisationName
                        })}</p>
                      ) : step.userType === "Investor" ? (
                        <p className={index !== updates.length - 1 ? "text-updates-grey font-semibold" : "text-highlight-blue font-semibold"}>{t('InvestorUpdate', {
                          userName: step?.User?.userName
                        })}</p>
                      ) : step.userType === "SEBI" ? (
                        <p className={index !== updates.length - 1 ? "text-updates-grey font-semibold" : "text-highlight-blue font-semibold"}>Admin {step?.User?.userName} posted an update.</p>
                      ) : null
                    )
                  )}

                  {step?.otherMetaData?.remarks &&
                    <div className="flex flex-col gap-2  text-xs py-4 w-full px-8 border border-border-grey bg-white rounded-xl">
                      <p className='text-updates-grey font-semibold'>{step?.otherMetaData?.remarks}</p>
                    </div>
                  }

                  <p className={index !== updates.length - 1 ? " text-updates-time-grey text-xs" : "text-updates-time-grey text-xs"}>{dateTimeHelper(step?.createdAt)}</p>
                  {step?.otherMetaData?.icon === 'clock-counter-clockwise' &&
                    <p className={index !== updates.length - 1 ? " text-updates-time-grey text-xs" : "text-updates-time-grey text-xs"}><i>{t('viaSCORES')}</i></p>
                  }

                  {step.userType !== "system" && (
                    <div className="flex flex-col gap-2 text-xs py-4 w-full px-8 border border-border-grey bg-white rounded-xl">
                      <p className={index !== updates.length - 1 ? "text-updates-grey text-xs" : "text-highlight-blue text-xs"}>{step.comment}</p>
                    </div>
                  )}
                  <div className="grid sm:gap-1 gap-2">
                    {step.files?.map((file: any, i: number) => {
                      return (
                        // <div key={i} onClick={(e) => downloadFile(file.file, step.complainUpdateId)} className="flex flex-row gap-2 bg-white p-2 border border-border-grey rounded-xl">
                        //   <i className="ph-bold ph-file-arrow-up" ></i>
                        //   <p className="sm:text-xs text-[10px]">{file.fileName.length > 20 ? (file.fileName).slice(0, 20) + '...' : file.fileName}</p>
                        // </div>
                        <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                          <div
                            onClick={(e) => generateLink(file.file, step?.complainUpdateId, file)}
                            className="relative flex flex-col cursor-pointer gap-2 text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey bg-white rounded-xl group overflow-hidden"
                          >
                            {/* File Name and Upload Info */}
                            <p>{file?.fileName?.length > 20 ? (file?.fileName).slice(0, 20) + '...' : file?.fileName}</p>
                            <div className="absolute gap-1 inset-0 bg-attachment-grey bg-opacity-70 flex items-center justify-center opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                              <i className="ph ph-eye text-label-grey text-lg"></i>
                              <p className="text-label-grey text-xs">{t('ViewDocument')}</p>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ))}
            {(
              user.userType === "ODR" ||
              user.userType === "MII" ||
              (user.userType === "Intermediary" && allowedStatuses.includes(complaintDetail.status)) ||
              (user.userType === "Investor" && allowedStatuses.includes(complaintDetail.status))
            ) && (

                <div className="flex flex-col gap-2 pl-2 sm:w-full w-full">
                  <div className="bg-white rounded-xl p-2 flex flex-col gap-2 border border-low-grey">
                    <textarea placeholder={t('SendUpdateYourCase')} name='newUpdate' id='newUpdate' value={newUpdate.comment} onChange={(e: any) => setnewUpdate({
                      ...newUpdate,
                      comment: e.target.value
                    })} rows={3} className="text-xs p-2" />
                    <div className="flex flex-row justify-between">
                      <div className="grid grid-cols-2  sm:grid-cols-3 sm:gap-1 gap-2">
                        {updateFiles?.map((file: any, i: any) => {
                          return (
                            <div key={i} className="flex flex-row w-fit gap-2 p-2 border border-border-grey rounded-xl">
                              <i className="ph-bold ph-file-arrow-up"></i>
                              <p className="sm:text-xs text-[10px]">{file?.fileName?.length > 20 ? (file?.fileName).slice(0, 20) + '...' : file.fileName}</p>
                              <i className="ph-bold ph-x text-xs" onClick={(e) => removeFile(file.fileName)}></i>
                            </div>
                          )
                        })}
                      </div>
                      <div className="flex flex-row gap-3 items-center">
                        <label htmlFor='upload' className="cursor-pointer">
                          <i className="ph-bold ph-paperclip text-lg text-back-gray hover:text-black"></i>
                          <input multiple aria-label={t('Choosefile')} onChange={(e) => handleUpdateFiles(e)} type="file" id='upload' style={{ display: 'none' }} accept=".jpeg, .pdf, .png, .jpg, .xlsx, .csv, .mp3, .zip, .mp4, .doc, .docx" />
                        </label>
                        <i className="ph-bold ph-paper-plane-tilt text-lg text-back-gray hover:text-black" onClick={(e) => addUpdate(e)}></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 text-[10px] text-back-gray">
                    <p>{t('AcceptedFormat')}</p>
                    <p>{t('Note')}: {t('TotalFileSize')}</p>
                  </div>
                </div>
              )}

          </div>
        </div>
      </div>
      <div className="w-full md:w-[30rem] pl-4 mt-6 lg:mt-0">
        <OutcomeDetails miiId={complaintDetail?.miiId} disputeId={complaintDetail?.complainId} claimAmount={complaintDetail?.claimAmount} status={complaintDetail?.status} />
      </div>
      {previewLink && (
        <div className="fixed z-10 inset-0 flex items-center justify-center " id="modal">
          <div className="px-8 sm:px-0 sm:w-2/3 h-3/4">
            <div className="flex justify-center pt-4 w-full h-full">
              <div
                className="fixed inset-0 transition-opacity"
                onClick={() => setPreviewLink("")}
              >
                <div className="absolute inset-0 bg-gray-900 opacity-75" />
              </div>
              <div
                className="inline-block align-center w-full bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="flex flex-col gap-1 px-8 pb-5" style={{ height: "-webkit-fill-available" }}>
                  <div className="flex flex-row w-full justify-between items-center">
                    <h1 className='font-semibold sm:text-2xl text-lg pt-3 mr-2'>{selectedFile?.fileName}&nbsp;&nbsp;
                      <Tooltip
                        html={
                          <div className=" text-left flex flex-col gap-1">
                            <p className="font-semibold">{t('Download')} {selectedFile?.fileName}</p>
                          </div>
                        }
                        position="top"
                        arrow={false}
                        animation="shift"
                        size="small"
                        {...({
                          children: <i onClick={() => downloadFile(selectedFile?.file, complainUpdateId!)}
                            className="ph ph-download-simple text-label-grey h-fit p-0.5" style={{ borderRadius: '50%' }}></i>
                        } as any)} // Type assertion to bypass TS errors
                      >
                      </Tooltip>
                    </h1>
                    <i className={`ph-bold ph-x text-lg text-label-grey cursor-pointer`} onClick={() => setPreviewLink("")}></i>
                  </div>
                  <DocumentViewerModal selectedFile={selectedFile} previewLink={previewLink}></DocumentViewerModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>

  )
}

export default ComplaintUpdates