import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useCombinedStore } from '../../../store';
import { MarketParticipantApproval, MarketParticipantRejection, Organisation, OrganisationParent, User } from '../../../swagger/models';
import { useTranslation } from 'react-i18next';
import { ApiController } from '../../../apiController';

function MarketParticipantVerification() {
  const { t } = useTranslation();
  const listOrganisations = useCombinedStore((state: any) => state.listOrganisations);
  const organisations: Organisation[] = useCombinedStore((state: any) => state.organisations);
  const rejectedMPApplication: MarketParticipantRejection = useCombinedStore((state: any) => state.rejectedMPApplication);
  const approvedMPApplication: MarketParticipantRejection = useCombinedStore((state: any) => state.approvedMPApplication);
  const approveMarketParticipant = useCombinedStore((state: any) => state.approveMarketParticipant);
  const rejectMarketParticipant = useCombinedStore((state: any) => state.rejectMarketParticipant);
  const [rejectionModal, setRejectionModal] = useState(false)
  const [approvalnModal, setApprovalModal] = useState(false)
  const [mpRejection, setMpRejection] = useState<MarketParticipantRejection>({})
  const [mpApproval, setMpApproval] = useState<MarketParticipantApproval>({})
  const [approvalArray, setApprovalArray] = useState<Array<number>>([])
  const [currentUser, setCurrentUser] = useState<User>({})
  const currentURL = window.location.href.split('/')[3]

  useEffect(() => {
    listOrganisations({
      orgType: 'INTERMEDIARY',
      limit: 25,
      offset: 0,
    });
  }, [rejectedMPApplication, approvedMPApplication]);

  useEffect(() => {
    ApiController.getInstance().user$.subscribe((user: any) => {
      if (user) {
       setCurrentUser(user) 
      }
    });
  }, [])

  const rejectMPApplication = (e: any) => {
    e.preventDefault();
    rejectMarketParticipant(mpRejection)
    setRejectionModal(false)
  };

  const performAction = (orgId: number) => {
    setMpRejection({
      ...mpRejection,
      orgId: orgId
    })
    setRejectionModal(true)
  }

  const approveMP = () => {
    approveMarketParticipant(mpApproval)
    setApprovalModal(false)
  }

  const approveMPAction = (orgId: number) => {
    setMpApproval({
      orgIds: [JSON.stringify(orgId)]
    })
    setApprovalModal(true)
  }

  const updateApprovalArray = (e: any, orgId: number) => {
    const orgParents = approvalArray || []
    if (e.checked) {
      // Add the organisation Id to the array list
      orgParents.push(orgId)
      setApprovalArray(orgParents)
    } else {
      // Remove the organisation Id from Array list
      const index = orgParents.findIndex((p: number) => p === orgId)
      orgParents.splice(index, 1)
      setApprovalArray(orgParents)
    }
    setMpApproval({
      orgIds: [JSON.stringify(orgParents)]
    })
  }

  return (
    <div className="pt-7 w-full h-full pr-4 pl-1 pb-7">
      <div
        style={{ background: '#F4F4F4' }}
        className="flex w-full h-full flex-initial px-8 py-7 flex-col gap-2 rounded-2xl">
        <p className='text-xs text-back-gray'><Link to={`/${currentURL}/settings`}>{t('Settings')}</Link> &gt; {t('UserManagement')}</p>
        <h1 className="text-3xl font-semibold text-highlight-blue pb-3">{t('Settings')}</h1>
        <div className="bg-white w-full h-full flex flex-col rounded-xl gap-7">
          <Link to={`/${currentURL}/settings`} className="py-3 border-b border-F5F5F5 text-xs pl-7">{t("Back")}</Link>
          <div className="flex flex-row pb-4 gap-3 pl-7 border-b border-F5F5F5">
            <i className="ph-bold ph-user-circle-plus p-3 border border-F5F5F5 rounded-full"></i>
            <div className="flex flex-col gap-2">
              <p className="text-sm">{t('MarketParticipants')}</p>
              <p className="text-xs text-back-gray">{t('ViewAllMP')}</p>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between pl-7">
            <div className="flex flex-row w-1/2 rounded-xl border border-border-grey pl-2">
              <i className="ph-bold ph-magnifying-glass p-2 "></i>
              <input type="text" name="search" id="search" aria-label={t('search')} className="text-xs py-2.5 w-full rounded-xl px-2" />
            </div>
            <div className="flex flex-row justify-end w-1/2 pl-2  pr-12 mt-1">
              <p onClick={() => setApprovalModal(true)} className="text-resolved-green hover:underline cursor-pointer font-medium text-xs mr-2">{t('Approve')}</p>
            </div>
          </div>
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="border-b py-3 font-normal text-left text-sm text-label-grey"></th>
                <th className="border-b py-3 font-normal text-left text-sm text-label-grey">
                  {t('Name')}
                </th>
                <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                </th>
              </tr>
            </thead>
            <tbody>
              {organisations?.filter((org: Organisation) => org.OrganisationParents?.filter((i: OrganisationParent) => i.status === 'AWAITING_APPROVAL' && i.parentId === currentUser.orgId)?.length > 0)?.map((org: Organisation) => {
                return (
                  <tr key={org?.orgId}>
                    <td className="border-b pl-7 font-normal text-xs">
                      <input type="checkbox"
                        onChange={(e) => updateApprovalArray(e.target, org?.orgId)}
                        name={org?.orgId}
                      />
                    </td>
                    <td className="border-b py-3 font-normal text-xs">
                      <Link to={`../organisation-management/market-participant/${org?.orgId}`}>{org?.organisationName}</Link>
                    </td>
                    <td className="border-b p-3 w-full font-normal text-xs flex flex-row gap-5 justify-end pr-14">
                      <p onClick={() => approveMPAction(org.orgId)} className="text-resolved-green hover:underline cursor-pointer font-medium">{t('Approve')}</p>
                      <p onClick={() => performAction(org.orgId)} className='text-odr-grey hover:underline cursor-pointer font-medium'>{t('Reject')}</p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {rejectionModal &&
        (
          <div
            className="fixed z-10 inset-0 flex items-center justify-center "
            id="modal"
          >
            <div className="px-8 sm:px-0 sm:w-1/3">
              <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={() => setRejectionModal(false)}
                >
                  <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <div
                  className="inline-block align-center w-fit bg-white border-2 border-highlight-red rounded-lg py-4 text-left shadow-xl transform transition-all "
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <form >
                    <div className="flex flex-col gap-1 px-8 pb-5">
                      <h1 className='font-semibold sm:text-2xl text-lg pt-3'>{t('RejectionHeader')}</h1>
                      <div className='flex flex-col gap-4 pb-3 pt-4 text-label-grey'>
                        <p className="text-xs text-label-grey">{t('RejectedSubHeader')}</p>
                        <textarea onChange={(e) => setMpRejection({
                          ...mpRejection,
                          rejectionReason: e.target.value
                        })} placeholder={t('Reasons')} name="bugReport" id="bugReport" rows={4} className="text-xs border rounded-lg w-full p-2" />
                        <p className="text-xs text-label-grey">{t('RejectionNote1')}</p>
                        <p className="text-xs text-label-grey">{t('RejectionNote2')}</p>
                      </div>
                      <div className='flex flex-col sm:flex-row w-full pt-4 gap-4'>
                        <button className="hover:bg-highlight-blue/[0.4] bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12" aria-label={t('Submit')}
                          onClick={rejectMPApplication}>
                          <p>{t('Proceed')}</p>
                        </button>
                        <button aria-label={t('cancel')} onClick={() => setRejectionModal(false)} className=" hover:bg-black/[0.1] cursor-pointer hover:text-white justify-center text-xs bg-white text-back-gray px-12 py-3 rounded-xl">
                          {t('cancel')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {approvalnModal &&
        (
          <div
            className="fixed z-10 inset-0 flex items-center justify-center "
            id="modal"
          >
            <div className="px-8 sm:px-0 sm:w-1/3">
              <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={() => setApprovalModal(false)}
                >
                  <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <div
                  className="inline-block align-center w-fit bg-white border-2 border-resolved-green rounded-lg py-4 text-left shadow-xl transform transition-all "
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <form >
                    <div className="flex flex-col gap-1 px-8 pb-5">
                      <h1 className='font-semibold sm:text-2xl text-lg pt-3'>{t('ApprovalHeader')}</h1>
                      <div className='flex flex-col gap-4 pb-3 pt-4 text-label-grey'>
                        <p className="text-xs text-label-grey">{t('ApprovalSubHeader')}</p>
                      </div>
                      <div className='flex flex-col sm:flex-row w-full pt-4 gap-4'>
                        <button className="hover:bg-highlight-green/[0.4] bg-highlight-green justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-green/[0.4] px-12" aria-label={t('Submit')}
                          onClick={approveMP}>
                          <p>{t('Proceed')}</p>
                        </button>
                        <button aria-label={t('cancel')} onClick={() => setApprovalModal(false)} className=" hover:bg-black/[0.1] cursor-pointer hover:text-white justify-center text-xs bg-white text-back-gray px-12 py-3 rounded-xl">
                          {t('cancel')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
}

export default MarketParticipantVerification